import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@3nvi/gatsby-theme-intl';

import MastheadBg from './sharpImages/mastheadBg';
import breakpoint from 'styled-components-breakpoint';
import Portrait from './portrait';
import gsap from 'gsap';

const MastheadContainer = styled.div`
  overflow: hidden;
  background-color: ${props => props.theme.grey};
  ${breakpoint('lg')`
    > section::after, > section::before {
      max-width: calc(100% - 170px);
    }
  }`};
`;

const MastheadContent = styled.div`
  bottom: 0;
  color: white;
  height: 100vh;
  min-height: 700px;
  max-width: 1540px;
  margin: 0 auto;
  padding-bottom: 30px;
  position: relative;
  > div {
    width: 100%;
    position: absolute;
    bottom: 10px;
  }
  h2 {
    background: ${props => props.theme.primary};
    font-size: 30px;
    font-weight: 700;
    padding: 20px 20px 20px 20px;
    max-width: 330px;
    position: relative;
    display: block;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -100vw;
      width: 100vw;
      height: 100%;
      background: ${props => props.theme.primary};
    }
  }
  p {
    padding: 0 20px;
    font-size: 18px;
    max-width: 330px;
    margin-bottom: 60px;
  }
  ${breakpoint('md')`
    h2 {
      font-size: 40px;
      padding: 20px 80px 20px 20px;
      max-width: 470px;
    }
    p {
      max-width: 470px;
    }
  }`}

  ${breakpoint('lg')`
    h2, p {
      max-width: 500px;
    }
    h2, p  {
      padding-left: 30px;
    }
    p {
      margin-bottom: 180px;
    }
  }`}

  ${breakpoint('xl')`
    h2, p  {
      padding-left: 40px;
    }
  }`}
`;

const Masthead = () => {
  const { t } = useTranslation();

  const wrapper = useRef(null);

  useEffect(() => {
    const elements = wrapper.current.children;
    gsap.set([elements], { autoAlpha: 0 });
    const tl = gsap.timeline({ defaults: { ease: 'power3.inOut' } });

    tl.fromTo(
      elements,
      { x: '-=100' },
      { x: '0', autoAlpha: 1, stagger: 0.2, duration: 1, delay: 0.4 }
    );
  });

  return (
    <MastheadContainer id="masthead">
      <MastheadBg>
        <MastheadContent>
          <div ref={wrapper}>
            <h2 id="mastheadTitle">{t('masthead.title')}</h2>
            <p>{t('masthead.description')}</p>
            {/* <Portrait /> */}
          </div>
        </MastheadContent>
      </MastheadBg>
    </MastheadContainer>
  );
};

export default Masthead;
