import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from '@3nvi/gatsby-theme-intl';
import breakpoint from 'styled-components-breakpoint';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import ContentBlock from './contentBlock';
import * as ServicesImages from '../images/services';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

const ServiceList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > div {
    flex: 0 0 48%;
  }
  ${breakpoint('md')`
    > div { flex: 0 0 32%; }
  `}
  ${breakpoint('lg')`
    > div { flex: 0 0 24%; max-width: 190px; }
  `}
  .gap {
    visibility: hidden;
  }
`;

const Service = styled.div`
  background: ${props => props.theme.grey};
  padding: 40px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  height: 210px;
  .service-name {
    font-size: 21px;
    font-weight: bold;
    text-align: center;
  }
  .service-image {
    max-height: 50px;
    margin-bottom: 38px;
  }
  &:nth-child(1),
  &:nth-child(4) {
    .service-image {
      max-height: 40px;
    }
    .service-name {
      padding-top: 10px;
    }
  }
`;

const Services = () => {
  const { t } = useTranslation();
  const services = [
    {
      key: 1,
      name: t('services.logo'),
      image: ServicesImages.Eye,
    },
    {
      key: 2,
      name: t('services.identification'),
      image: ServicesImages.Pencil,
    },
    {
      key: 3,
      name: t('services.ads'),
      image: ServicesImages.Settings,
    },
    {
      key: 4,
      name: t('services.photos_and_videos'),
      image: ServicesImages.Camera,
    },
    {
      key: 5,
      name: t('services.prints'),
      image: ServicesImages.Printer,
    },
    {
      key: 6,
      name: t('services.marketing'),
      image: ServicesImages.Flag,
    },
    {
      key: 7,
      name: t('services.websites'),
      image: ServicesImages.Websites,
    },
  ];

  useEffect(() => {
    var elements = document.querySelectorAll('.service');
    gsap.set(elements, { autoAlpha: 0 });
    gsap.fromTo(
      elements,
      { y: '+=100' },
      {
        y: '0',
        autoAlpha: 1,
        stagger: 0.2,
        duration: 0.6,
        ease: 'easeInOut',
        scrollTrigger: {
          trigger: '#offer',
          start: 'top 40%',
        },
      }
    );
  });

  return (
    <ContentBlock id="offer" title={t('menu.services')} description={t('services.description')}>
      <ServiceList>
        {services.map(service => (
          <Service key={service.key} className="service">
            <img src={service.image} alt={service.name} className="service-image" />
            <span className="service-name" dangerouslySetInnerHTML={{ __html: service.name }} />
          </Service>
        ))}
        <div key="gap-1" className="service gap" />
        <div key="gap-2" className="service gap" />
        <div key="gap-3" className="service gap" />
      </ServiceList>
    </ContentBlock>
  );
};

export default Services;
