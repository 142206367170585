import { useTranslation } from '@3nvi/gatsby-theme-intl';
import React from 'react';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import { ArrowLinkRight, ArrowInternalLinkRight } from './buttons';
import PortraitImage from './sharpImages/portrait';

const PortraitContainer = styled.div`
  display: flex;
  padding: 20px 20px;
  ${breakpoint('lg')`margin-left: 10px;`}
  align-items: center;
  > div:first-child {
    flex: 0 0 115px;
    ${breakpoint('md')`flex: 0 0 139px;`}
  }
  .portrait-content {
    margin-left: 30px;
    h3 {
      font-size: 23px;
      font-weight: bold;
      margin-bottom: 3px;
    }
    span {
      font-size: 18px;
      font-weight: 400;
      display: block;
      margin-bottom: 5px;
    }
  }
`;

const Portrait = () => {
  const { t } = useTranslation();
  return (
    <PortraitContainer>
      <PortraitImage />
      <div className="portrait-content">
        <h3>Tomasz Tobys</h3>
        <span className="position">{t('masthead.position')}</span>
        <ArrowInternalLinkRight
          textColor="white"
          to="/tomasz-tobys"
          target="_blank"
          rel="noreferrer"
        >
          {t('read_more')}
        </ArrowInternalLinkRight>
      </div>
    </PortraitContainer>
  );
};

export default Portrait;
