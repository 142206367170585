import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@3nvi/gatsby-theme-intl';

import ContentBlock from './contentBlock';
import { clients } from '../images/clients/index.jsx';
import breakpoint from 'styled-components-breakpoint';

const Client = styled.a`
  margin: 25px 0px;
  margin-right: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  ${breakpoint('sm')`
    width: auto;
  `}
  ${breakpoint('lg')`
    margin: 35px 0;
    margin-right: 80px;
  `}
`;

const ClientsList = styled.div`
  padding-top: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  a:nth-child(-n + 5) {
    display: flex;
  }
  a {
    display: none;
  }
  ${breakpoint('sm')`
    a:nth-child(-n + 10) {
      display: flex;
    }
  `}
  ${breakpoint('lg')`
    a:nth-child(-n + 40) {
      display: flex;
    }
  `}
`;

const Clients = () => {
  const { t } = useTranslation();
  return (
    <ContentBlock id="clients" title={t('menu.clients')}>
      <ClientsList>
        {clients
          // .sort((a, b) => a.key - b.key)
          .map((client, key) => (
            <Client
              href={client.url}
              target="_blank"
              rel="noreferrer"
              key={key}
              // height={client.height}
            >
              {client.img}
            </Client>
          ))}
      </ClientsList>
    </ContentBlock>
  );
};

export default Clients;
