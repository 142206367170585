import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import Masthead from '../components/masthead';
import Services from '../components/services';
import Portfolio from '../components/portfolio';
import Clients from '../components/clients';
import Projects from '../components/projects';
import Footer from '../components/footer';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="studio FNC" />
      <Masthead />
      <Services />
      <Portfolio />
      <Clients />
      <Projects />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
