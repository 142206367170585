import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@3nvi/gatsby-theme-intl';

import ContentBlock from './contentBlock';
import ProjectCard from './projectCard';

import FNCLogo from '../images/projects/fnc.svg';
import TeappLogo from '../images/projects/teapp.svg';
import DyplomyLogo from '../images/projects/dyplomy.svg';
import GPJPLogo from '../images/projects/gpjp.svg';
import breakpoint from 'styled-components-breakpoint';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

const ProjectsList = styled.div`
  margin-top: 70px;
  ${breakpoint('xl')`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    > div {
      flex: 0 0 48%;
    }
  `}
`;

const Projects = () => {
  const { t } = useTranslation();
  return (
    <ContentBlock
      id="projects"
      title={t('menu.projects')}
      description={t('projects.description')}
      background="#f6f6f6"
    >
      <ProjectsList>
        <ProjectCard
          logo={FNCLogo}
          title={t('projects.fnc.title')}
          description={t('projects.fnc.description')}
          buttonText={t('projects.fnc.button')}
          imageHeight={29}
          mainColor="#000000"
          url="https://www.facebook.com/fontnieczcionka"
        />
        <ProjectCard
          logo={DyplomyLogo}
          title={t('projects.dyplomy.title')}
          description={t('projects.dyplomy.description')}
          buttonText={t('projects.dyplomy.button')}
          imageHeight={80}
          mainColor="#FBEC23"
          url="https://dyplomy.fontnieczcionka.pl"
        />
        <ProjectCard
          logo={GPJPLogo}
          title={t('projects.gpjp.title')}
          description={t('projects.gpjp.description')}
          buttonText={t('projects.gpjp.button')}
          imageHeight={47}
          mainColor="#218E87"
          url="https://www.facebook.com/grafikplakal"
        />
        <ProjectCard
          logo={TeappLogo}
          title={t('projects.teapp.title')}
          description={t('projects.teapp.description')}
          buttonText={t('projects.teapp.button')}
          imageHeight={62}
          mainColor="#E7790B"
          url="https://teapp.pl"
        />
      </ProjectsList>
    </ContentBlock>
  );
};

export default Projects;
