import React from 'react';
import styled from 'styled-components';

import { ArrowLinkRight } from './buttons';

const ProjectCardContainer = styled.div`
  margin-bottom: 50px;
  .image-wrapper {
    padding: 20px 30px;
    border-left: 5px solid ${props => props.mainColor};
    height: 105px;
    display: flex;
    align-items: center;
    img {
      height: ${props => props.imageHeight}px;
    }
  }
  p {
    padding-left: 30px;
    font-size: 18px;
    line-height: 1.3;
    max-width: 630px;
    margin-bottom: 20px;
  }
  a {
    flex: 0 0 auto;
    padding-left: 30px;
  }
`;

const ProjectCard = ({ mainColor, imageHeight, logo, title, description, buttonText, url }) => {
  return (
    <ProjectCardContainer imageHeight={imageHeight} mainColor={mainColor}>
      <div className="image-wrapper">
        <img src={logo} alt={title} />
      </div>
      <p>{description}</p>
      <ArrowLinkRight href={url} target="_blank" rel="noreferrer" textColor="black">
        {buttonText}
      </ArrowLinkRight>
    </ProjectCardContainer>
  );
};

export default ProjectCard;
