import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

const MastheadBg = ({ children }) => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "masthead.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 3000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage
      Tag="section"
      fluid={data.background.childImageSharp.fluid}
      backgroundColor={`black`}
    >
      {' '}
      {children}
    </BackgroundImage>
  );
};

export default MastheadBg;
