import PropTypes from 'prop-types';
import styled from 'styled-components';
import React, { useState, useEffect } from 'react';

import logo from '../images/logo.svg';
import loginIcon from '../images/login.svg';
import Content from './content';
import Menu from './menu';
import LanguageSwitcher from './languageSwitcher';
import breakpoint from 'styled-components-breakpoint';
import { Link } from '@3nvi/gatsby-theme-intl';

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  transition: background-color 0.5s;
  background-color: ${props => (props.scrolled ? 'black' : 'transparent')};
  .header-content {
    max-width: calc(100% - (calc(100% - 1540px) / 2));
    margin-right: 0;
  }
  ${breakpoint('lg')`
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }`}
`;

const Navbar = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${breakpoint('lg')`
    padding: 30px;
  }`}
  ${breakpoint('xl')`
    padding: 40px;
  }`}
`;

const Logo = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  img {
    height: 35px;
  }
  h1 {
    display: none;
  }
`;

const Toggle = styled.div`
  display: none;
  height: 35px;
  cursor: pointer;
  @media (max-width: 991px) {
    display: flex;
  }
`;

const Navbox = styled.div`
  /* height: 100%; */
  ${breakpoint('lg')`
    > div {
      display: flex;
      align-items: center;
    }
  }`}
  @media (max-width: 991px) {
    z-index: -1;
    flex-direction: column;
    position: fixed;
    width: 100%;
    justify-content: flex-start;
    background-color: black;
    transition: all 0.3s ease-in;
    top: 0px;
    padding-top: 100px;
    left: ${props => (props.open ? '-100%' : '0')};
  }
`;

const LoginLink = styled.a`
  margin: 20px 0;
  display: flex;
  align-items: center;
  color: white;
  text-decoration: none;
  padding: 3px;
  transition: 0.3s;
  width: 80px;
  img {
    height: 28px;
    margin-right: 10px;
    filter: invert(1);
  }
  &:hover,
  &:focus {
    background-color: ${props => props.theme.primary};
  }
  ${breakpoint('lg')`
    width: auto;
    margin-left: 30px;
    text-indent: -100vw;
    font-size: 0;
    img {
      margin-right: 0;
    }
  `}

  @media screen and (min-width: 992px) {
    img {
      filter: ${props => (props.scrolled === 'true' ? `invert(1)` : `invert(0)`)};
    }
    &:hover,
    &:focus {
      background-color: ${props =>
        props.scrolled === 'true' ? `${props.theme.primary}` : `transparent`};
    }
  }
`;

const Hamburger = styled.div`
  background-color: #fff;
  width: 30px;
  height: 3px;
  transition: all 0.3s linear;
  align-self: center;
  position: relative;
  transform: ${props => (props.open ? 'rotate(-45deg)' : 'inherit')};

  ::before,
  ::after {
    width: 30px;
    height: 3px;
    background-color: #fff;
    content: '';
    position: absolute;
    transition: all 0.3s linear;
  }

  ::before {
    transform: ${props => (props.open ? 'rotate(-90deg) translate(-10px, 0px)' : 'rotate(0deg)')};
    top: -10px;
  }

  ::after {
    opacity: ${props => (props.open ? '0' : '1')};
    transform: ${props => (props.open ? 'rotate(90deg) ' : 'rotate(0deg)')};
    top: 10px;
  }
`;

const scrolledBreakpoint = 50;

const Header = ({ siteTitle }) => {
  const [navbarOpen, setNavbarOpen] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    document.addEventListener('scroll', () => {
      setScrollTop(window.pageYOffset);
    });
  }, [scrollTop]);
  useEffect(() => {
    setScrollTop(window.pageYOffset);
  }, []);
  return (
    <HeaderWrapper scrolled={scrollTop > scrolledBreakpoint ? true : false}>
      <Content nopadding className="header-content">
        <Navbar>
          <Logo to="/">
            <img src={logo} alt={siteTitle} />
            <h1>studio FNC</h1>
          </Logo>
          <Toggle navbarOpen={navbarOpen} onClick={() => setNavbarOpen(!navbarOpen)}>
            {navbarOpen ? <Hamburger open /> : <Hamburger />}
          </Toggle>
        </Navbar>
        {navbarOpen ? (
          <Navbox>
            <Content>
              <Menu />
              <LanguageSwitcher scrolled={scrollTop > scrolledBreakpoint ? 'true' : 'false'} />
              <LoginLink
                scrolled={scrollTop > scrolledBreakpoint ? `true` : `false`}
                href="https://panel.studiofnc.pl"
              >
                <img src={loginIcon} alt="Ikonka logowania" />
                Panel
              </LoginLink>
            </Content>
          </Navbox>
        ) : (
          <Navbox open>
            <Content>
              <Menu />
              <LanguageSwitcher scrolled={scrollTop > scrolledBreakpoint ? 'true' : 'false'} />
              <LoginLink
                scrolled={scrollTop > scrolledBreakpoint ? `true` : `false`}
                href="https://panel.studiofnc.pl"
              >
                <img src={loginIcon} alt="Ikonka logowania" />
                Panel
              </LoginLink>
            </Content>
          </Navbox>
        )}
      </Content>
    </HeaderWrapper>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
