import React from 'react';
import styled from 'styled-components';
import { useTranslation } from '@3nvi/gatsby-theme-intl';
import breakpoint from 'styled-components-breakpoint';
import { Link } from 'react-scroll';

const MenuContainer = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  a {
    cursor: pointer;
    color: white;
    text-decoration: none;
    font-size: 20px;
    text-transform: lowercase;
    padding: 3px;
    transition: 0.3s;
    &:hover,
    &:focus {
      background-color: ${props => props.theme.primary};
    }
  }
  ${breakpoint('lg')`
    display: inline-block;
    li {
      margin: 0 11px;
      display: inline-block;
    }
  }`}
  ${breakpoint('xl')`
    li {
      margin: 0 30px;
    }
  }`}
`;

const Menu = ({ nopadding }) => {
  const { t } = useTranslation();
  return (
    <MenuContainer nopadding={nopadding}>
      <li>
        <Link to="offer" spy={true} smooth={true} offset={-70} duration={500}>
          {t('menu.services')}
        </Link>
      </li>
      <li>
        <Link to="portfolio" spy={true} smooth={true} offset={-70} duration={500}>
          {t('menu.portfolio')}
        </Link>
      </li>
      <li>
        <Link to="clients" spy={true} smooth={true} offset={-70} duration={500}>
          {t('menu.clients')}
        </Link>
      </li>
      <li>
        <Link to="projects" spy={true} smooth={true} offset={-70} duration={500}>
          {t('menu.projects')}
        </Link>
      </li>
      <li>
        <Link to="contact" spy={true} smooth={true} offset={-70} duration={500}>
          {t('menu.contact')}
        </Link>
      </li>
    </MenuContainer>
  );
};

export default Menu;
