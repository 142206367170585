import React from 'react';
import { useTranslation } from '@3nvi/gatsby-theme-intl';
import styled from 'styled-components';

import { BlockWrapper } from './contentBlock';
import { ArrowLinkRight } from './buttons';
import breakpoint from 'styled-components-breakpoint';
import PortfolioVideo from '../videos/portfolio.mp4';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.core.globals('ScrollTrigger', ScrollTrigger);

const MoreOnBehance = styled.div`
  margin: 50px 0;
  a {
    background-color: #1111f5;
    display: inline-block;
    width: 360px;
    max-width: 100%;
    padding: 15px 20px;
    padding-right: 45px;
    letter-spacing: unset;
    text-transform: unset;
    background-size: auto 30%;
    background-position: center right 20px;
    font-size: 22px;
    font-weight: bold;
    color: white;
    text-decoration: none;
    transition: 0.3s;
    &:hover,
    &:focus {
      background-position: center right 10px;
      opacity: 0.8;
    }
  }
`;

const GridContainer = styled.div`
  max-width: 1540px;
  padding: ${props => (props.nopadding ? '0' : '0 20px')};
  margin: 0 auto;
  ${breakpoint('lg')` 
    padding: ${props => (props.nopadding ? '0' : '0 30px')};
  `}
  ${breakpoint('lg')` 
    padding: ${props => (props.nopadding ? '0' : '0 40px')};
  `}
  ${breakpoint('lg')`
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
  `}
  ${breakpoint('xl')`
    grid-template-columns: 2fr 3fr;
  `}
`;
const TextBlock = styled.div`
  padding-top: 40px;
  ${breakpoint('lg')`
    padding-top: 110px;
  `}
`;
const VideoContainer = styled.div`
  margin: 0 -20px;
  ${breakpoint('md')`
margin: 0 0px;
`}
`;
const Video = styled.video``;
const BlockWrapper2 = styled(BlockWrapper)`
  ${breakpoint('lg')`
padding-bottom: 0px;
`}
`;

const Portfolio = () => {
  const { t } = useTranslation();
  return (
    <BlockWrapper2
      id="portfolio"
      className="block-wrapper"
      style={{ background: 'linear-gradient(#f6f6f6, white)', paddingTop: 0 }}
    >
      <GridContainer>
        <TextBlock className="block">
          <h2 className="block-title">{t('menu.portfolio')}</h2>
          <p className="block-description">{t('portfolio.description')}</p>
          <MoreOnBehance>
            <ArrowLinkRight
              href={'https://www.behance.net/studioFNCpl'}
              target="_blank"
              rel="noreferrer"
            >
              {t('portfolio.behance')}
            </ArrowLinkRight>
          </MoreOnBehance>
        </TextBlock>
        <VideoContainer className="video">
          <Video width="100%" height="100%" autoPlay loop muted playsInline>
            <source src={PortfolioVideo} type="video/mp4" />
            Twoja przeglądarka nie wspiera odtwarzania wideo.
          </Video>
        </VideoContainer>
      </GridContainer>
    </BlockWrapper2>
  );
};

export default Portfolio;
