import React from 'react';
import teatr_polski from './icons/teatr_polski.svg';
import teatr_ateneum from './icons/teatr_ateneum.svg';
import teatr_powszechny from './icons/teatr_powszechny.svg';
import mdag from './icons/mdag.svg';
import teatr_bydgoszcz from './icons/teatr_bydgoszcz.svg';
import teatr_zaglebia from './icons/teatr_zaglebia.svg';
import teatr_studio from './icons/teatr_studio.svg';
import teatr_szekspirowski from './icons/teatr_szekspirowski.svg';
import teatr_dramatyczny from './icons/teatr_dramatyczny.svg';
import teatr_rampa from './icons/teatr_rampa.svg';
import teatr_guliwer from './icons/teatr_guliwer.svg';
import polin from './icons/polin.svg';
import teatr_stary from './icons/teatr_stary.svg';
import teatr_nowy from './icons/teatr_nowy.svg';
import animacji from './icons/animacji.svg';
import osterwy from './icons/osterwy.svg';
import ikm from './icons/ikm.svg';
import teatr_wspolczesny from './icons/teatr_wspolczesny.svg';
import wierszalin from './icons/wierszalin.svg';
import komediowy from './icons/komediowy.svg';
import ptt from './icons/ptt.svg';
import laznianowa from './icons/laznianowa.svg';
import syrena from './icons/syrena.svg';
import radom from './icons/radom.svg';
import prom from './icons/prom.svg';
import instytutteatralny from './icons/instytut-teatralny.svg';
import bb from './icons/bb.svg';
import polskipoznan from './icons/polskipoznan.svg';
import boskakomedia from './icons/boskakomedia.svg';
import wst from './icons/wst.svg';
import wow from './icons/wow.svg';
import moleko from './icons/moleko.svg';
import artystyczna from './icons/artystyczna.svg';
import claro from './icons/claro.svg';
import bplusb from './icons/bplusb.svg';

export const clients = [
  {
    url: 'https://teatr.bielsko.pl',
    img: (
      <img src={teatr_polski} width={132} height={90} alt="Logo Teatr Polski w Bielsku-Białej" />
    ),
  },
  {
    url: 'https://teatrateneum.pl',
    img: <img src={teatr_ateneum} width={243} height={22} alt="Logo Teatr Ateneum w Warszawie" />,
  },
  {
    url: 'https://www.powszechny.com',
    img: (
      <img src={teatr_powszechny} width={265} height={58} alt="Logo Teatr Powszechny w Warszawie" />
    ),
  },
  {
    url: 'https://mdag.pl.com',
    img: <img src={mdag} width={122.5} height={90} alt="Logo Millenium Docs Against Gravity" />,
  },
  {
    url: 'https://teatrpolski.pl',
    img: <img src={teatr_bydgoszcz} width={132} height={90} alt="Logo Teatr Polski w Bydgoszczy" />,
  },
  {
    url: 'https://www.moleko.pl/',
    img: <img src={moleko} width={184} height={33} alt="Logo Moleko" />,
  },
  {
    url: 'https://bplusb.pl/',
    img: <img src={bplusb} width={86} height={84} alt="Logo BplusB" />,
  },
  {
    url: 'https://artystycznaalternatywa.pl/',
    img: <img src={artystyczna} width={277.5} height={60} alt="Logo Artystyczna Alternatywa" />,
  },
  {
    url: 'https://wowpictures.tv/',
    img: <img src={wow} width={78} height={71} alt="Logo Wow Pictures" />,
  },
  {
    url: 'https://claro.pl',
    img: <img src={claro} width={201} height={50} alt="Logo Claro" />,
  },
  {
    url: 'https://teatrzaglebia.pl',
    img: (
      <img src={teatr_zaglebia} width={170.6} height={66.8} alt="Logo Teatr Zagłębia w Sosnowcu" />
    ),
  },
  {
    url: 'https://teatrstudio.pl',
    img: <img src={teatr_studio} width={197.8} height={90.5} alt="Logo Teatr Studio" />,
  },
  {
    url: 'https://teatrszekspirowski.pl',
    img: (
      <img src={teatr_szekspirowski} width={229.8} height={74.2} alt="Logo Teatr szekspirowski" />
    ),
  },
  {
    url: 'https://teatrdramatyczny.pl',
    img: (
      <img
        src={teatr_dramatyczny}
        width={91.8}
        height={91.9}
        alt="Logo Teatr Dramatyczny w&nbsp;Warszawie"
      />
    ),
  },
  {
    url: 'https://teatr-rampa.pl',
    img: <img src={teatr_rampa} width={165.5} height={56} alt="Logo Teatr Rampa" />,
  },
  {
    url: 'http://teatrguliwer.waw.pl',
    img: <img src={teatr_guliwer} width={132.1} height={73} alt="Logo Teatr Guliwer" />,
  },
  {
    url: 'http://polin.pl',
    img: <img src={polin} width={182} height={91} alt="Logo Polin" />,
  },
  {
    url: 'https://stary.pl',
    img: <img src={teatr_stary} width={254.5} height={83} alt="Logo Teatr Stary" />,
  },
  {
    url: 'https://nowyteatr.org',
    img: <img src={teatr_nowy} width={172.3} height={74} alt="Logo Nowy Teatr" />,
  },
  {
    url: 'https://teatranimacji.pl/',
    img: <img src={animacji} width={180.2} height={50.2} alt="Logo Teatr Animacji" />,
  },
  {
    url: 'https://teatrosterwy.pl/',
    img: <img src={osterwy} width={130.08} height={84.33} alt="Logo Teatr Osterwy" />,
  },
  {
    url: 'https://ikm.gda.pl/',
    img: <img src={ikm} width={106.95} height={69.11} alt="Logo Instytut Kultury Miejskiej" />,
  },
  {
    url: 'https://wspolczesny.szczecin.pl',
    img: <img src={teatr_wspolczesny} width={337} height={46.2} alt="Logo Teatr Współczesny" />,
  },
  {
    url: 'https://wierszalin.pl/',
    img: <img src={wierszalin} width={94} height={94} alt="Logo Teatr wierszalin" />,
  },
  {
    url: 'https://komediowy.pl/',
    img: <img src={komediowy} width={194} height={68.29} alt="Logo Klub Komediowy" />,
  },
  {
    url: 'https://ptt-poznan.pl/',
    img: <img src={ptt} width={221.16} height={52.67} alt="Logo Polski Teatr Tańca" />,
  },
  {
    url: 'https://laznianowa.pl/',
    img: <img src={laznianowa} width={106} height={106} alt="Logo Łaźnia Nowa" />,
  },
  {
    url: 'https://teatrsyrena.pl/',
    img: <img src={syrena} width={249.6} height={35.2} alt="Logo Teatr Syrena" />,
  },
  {
    url: 'https://www.teatr.radom.pl/',
    img: <img src={radom} width={84.2} height={79.4} alt="Logo Teatr Powszechny w Radomiu" />,
  },
  {
    url: 'https://promkultury.pl/',
    img: <img src={prom} width={179.4} height={70.3} alt="Logo PROM Kultury Saska Kępa" />,
  },
  {
    url: 'https://www.instytut-teatralny.pl/',
    img: (
      <img src={instytutteatralny} width={197.61} height={25.94} alt="Logo Instytut Teatralny" />
    ),
  },
  {
    url: 'https://bielsko-biala.pl/',
    img: <img src={bb} width={182.47} height={50.55} alt="Logo Bielsko-Biała" />,
  },
  {
    url: 'https://teatr-polski.pl/',
    img: <img src={polskipoznan} width={299} height={42.2} alt="Logo Teatr Polski w Poznaniu" />,
  },
  {
    url: 'https://boskakomedia.pl/',
    img: <img src={boskakomedia} width={69.3} height={79.65} alt="Logo Boska Komedia" />,
  },
  {
    url: 'https://warszawskie.org',
    img: <img src={wst} width={215.52} height={61.72} alt="Logo Warszawskie Spotkania Teatralne" />,
  },
];
