import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import { usePageContext } from '@3nvi/gatsby-theme-intl';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

const StyledSwitcher = styled.div`
  margin-top: 50px;
  color: white;
  a {
    text-decoration: none;
    color: white;
    padding: 3px;
    transition: 0.3s;
    span.active {
      font-weight: bold;
    }
    margin-right: 10px;
    &:not(:first-child) {
      margin-left: 10px;
      margin-right: 0;
    }
    &:focus,
    &:hover {
      background-color: ${props => props.theme.primary};
    }
  }
  ${breakpoint('lg')`
    margin-top: 0;
    display: inline-block;
    margin-left: 50px;
    font-size: 16px;
    a {
      margin-right: 0;
      &:hover, &:focus {
        background-color: transparent;
      }
    }
    a:not(:first-child) {
      margin: 0;
      &::before {
        padding: 0;
      }
    }
  }`}
  @media screen and (min-width: 992px) {
    color: ${props => (props.scrolled === 'true' ? 'white' : 'black')};
    a {
      color: ${props => (props.scrolled === 'true' ? 'white' : 'black')};
      &:hover,
      &:focus {
        background-color: ${props =>
          props.scrolled === 'true' ? `${props.theme.primary}` : `transparent`};
      }
    }
  }
`;

const LanguageSwitcher = ({ scrolled }) => {
  const { lang, supportedLanguages, originalPath } = usePageContext();
  return (
    <StyledSwitcher scrolled={scrolled}>
      {supportedLanguages.map((supportedLang, index) => (
        <span key={supportedLang} style={{ visibility: 'hidden' }}>
          {index !== 0 ? '/' : ''}
          {/* <GatsbyLink
            aria-label={`Zmień język na ${supportedLang}`}
            key={supportedLang}
            to={`/${supportedLang}${originalPath}`}
          > */}
          <span className={lang === supportedLang ? 'active' : ''}>{supportedLang}</span>
          <span className={lang === supportedLang ? 'active' : ''}>{supportedLang}</span>
          {/* </GatsbyLink> */}
        </span>
      ))}
    </StyledSwitcher>
  );
};

export default LanguageSwitcher;
